import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown, FaEllipsisH, FaRegBookmark, FaFlag} from 'react-icons/fa';
import { MdOutlineIosShare, MdDoNotDisturb } from "react-icons/md";

const recommendedVideos = [
  { id: 1, title: 'React Tutorial for Beginners', channel: 'CodeAcademy', views: '1M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 2, title: 'JavaScript ES6 Crash Course', channel: 'TechWithTim', views: '500K views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 3, title: 'Full Stack Development', channel: 'Traversy Media', views: '2M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 4, title: 'React Tutorial for Beginners', channel: 'CodeAcademy', views: '1M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 5, title: 'JavaScript ES6 Crash Course', channel: 'TechWithTim', views: '500K views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 6, title: 'Full Stack Development', channel: 'Traversy Media', views: '2M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 3, title: 'Full Stack Development', channel: 'Traversy Media', views: '2M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 4, title: 'React Tutorial for Beginners', channel: 'CodeAcademy', views: '1M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 5, title: 'JavaScript ES6 Crash Course', channel: 'TechWithTim', views: '500K views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  { id: 6, title: 'Full Stack Development', channel: 'Traversy Media', views: '2M views', thumbnail: '/images/dummy_168x94_ffffff_cccccc_thumbnail-img.png' },
  // Add more recommended videos
];

const commentsData = [
  { id: 1, user: 'John Doe', comment: 'Great video, learned a lot!', likes: 10, dislikes: 1 },
  { id: 2, user: 'Jane Smith', comment: 'Awesome explanation, thank you!', likes: 7, dislikes: 1 },
  { id: 3, user: 'Mike Johnson', comment: 'Loved this tutorial.', likes: 5, dislikes: 1 },
  { id: 4, user: 'John Doe', comment: 'Great video, learned a lot!', likes: 10, dislikes: 2 },
  { id: 5, user: 'Jane Smith', comment: 'Awesome explanation, thank you!', likes: 7, dislikes: 1 },
  { id: 6, user: 'Mike Johnson', comment: 'Loved this tutorial.', likes: 5, dislikes: 1 },
  // Add more comments
];

const ContentDetails = () => {
  const [comments, setComments] = useState(commentsData);
  const [newComment, setNewComment] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);  // Toggle for expanding description
  const [likedComments, setLikedComments] = useState({});  // Track likes state per comment
  const [dislikedComments, setDislikedComments] = useState({});  // Track dislikes state per comment
  const [showOptions, setShowOptions] = useState(false);


  const description = `This is a complete React JS tutorial for beginners. You'll learn everything from basic concepts to advanced patterns.
    By the end of this video, you'll be ready to build amazing React applications. React is a powerful library for building user interfaces, and mastering it can help you build complex and scalable web applications. This is a complete React JS tutorial for beginners. You'll learn everything from basic concepts to advanced patterns.
    By the end of this video, you'll be ready to build amazing React applications. React is a powerful library for building user interfaces, and mastering it can help you build complex and scalable web applications.`;

  const getShortDescription = (text) => {
    return text.split(' ').slice(0, 55).join(' ') + '...';  // Adjust the number to control the length
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      setComments([...comments, { id: comments.length + 1, user: 'You', comment: newComment, likes: 0 }]);
      setNewComment('');
    }
  };

  // Handle like action
  const handleLike = (commentId) => {
    setLikedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle the like state
    }));
    setDislikedComments((prev) => ({
      ...prev,
      [commentId]: false, // Reset dislike if like is clicked
    }));
  };

  // Handle dislike action
  const handleDislike = (commentId) => {
    setDislikedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle the dislike state
    }));
    setLikedComments((prev) => ({
      ...prev,
      [commentId]: false, // Reset like if dislike is clicked
    }));
  };

  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  // Handle like click
  const handleVideoLike = () => {
    setLiked(!liked);
    setDisliked(false);  // Reset dislike when like is clicked
  };

  // Handle dislike click
  const handleVideoDislike = () => {
    setDisliked(!disliked);
    setLiked(false);  // Reset like when dislike is clicked
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);  // Toggle visibility of options menu
  };

  return (
    <div className="container mt-4 content-details-page text-white">
      <div className="row">
        {/* Left: Video Player and Video Details */}
        <div className="col-lg-8">
          {/* Video Player */}
          <div className="embed-responsive video-frame embed-responsive-16by9 mb-3">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              allowFullScreen
              title="Video Player"
            />
          </div>

          <div>
            <div className="d-flex justify-content-between align-items-center mb-2">
              {/* Left side: Title and Info */}
              <div>
                <h3>React JS Tutorial - Full Course for Beginners</h3>
                <p>1M views • 1 week ago</p>
              </div>

              {/* Right side: Like, Dislike, and More Options */}
              <div className="d-flex align-items-center group-logic">
                {/* Like Button */}
                <div className="d-flex align-items-center me-3">
                  <FaThumbsUp
                    style={{
                      cursor: 'pointer',
                      color: liked ? '#4387ff' : 'black',  // Change color if liked
                      marginRight: '5px',
                    }}
                    onClick={handleVideoLike}  // Like action
                  />
                  <span>{liked ? 1 : 0}</span>  {/* Example like count */}
                </div>

                {/* Dislike Button */}
                <div className="d-flex align-items-center me-3">
                  <FaThumbsDown
                    style={{
                      cursor: 'pointer',
                      color: disliked ? 'red' : 'black',  // Change color if disliked
                      marginRight: '5px',
                    }}
                    onClick={handleVideoDislike}  // Dislike action
                  />
                  <span>{disliked ? 1 : 0}</span>  {/* Example dislike count */}
                </div>

                {/* Options Button */}
                {/* Options Button */}
                <div style={{ position: 'relative' }}>
                  <FaEllipsisH
                    style={{ cursor: 'pointer' }}
                    onClick={toggleOptions}  // Toggle options on click
                  />

                  {showOptions && (
                    <div
                      className="moreOption position-absolute"
                    >
                      <ul className="list-unstyled mb-0">
                        <li className="mb-1 d-flex align-items-center">
                          <MdOutlineIosShare className="me-2" />  {/* Add margin between icon and text */}
                          Share
                        </li>
                        <li className="mb-1 d-flex align-items-center">
                          <FaRegBookmark className="me-2" />  {/* Add margin between icon and text */}
                          Save
                        </li>
                        <li className="mb-1 d-flex align-items-center">
                          <FaFlag className="me-2" />  {/* Add margin between icon and text */}
                          Report
                        </li>
                        <li className="mb-1 d-flex align-items-center">
                          <MdDoNotDisturb className="me-2" />  {/* Add margin between icon and text */}
                          Not intrested
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr />
          </div>
          {/* Video Description */}
          <div>
            <h5>Description</h5>
            <p>
              {isExpanded ? description : getShortDescription(description)}{' '}
              <span
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ color: 'white', cursor: 'pointer' }}
              >
                {isExpanded ? 'Show Less' : 'More'}
              </span>
            </p>
          </div>

          <hr />

          {/* Comments Section */}
          <div>
            <h5>Comments ({comments.length})</h5>
            <form onSubmit={handleCommentSubmit} className="mb-3">
              <div className="mb-3">
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder="Add a comment..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
              </div>
              <button type="submit" className="btn theme-btn-one">Comment</button>
            </form>

            {comments.map((comment) => (
              <div key={comment.id} className="d-flex align-items-start mb-3">
                {/* Commenter's profile circle */}
                <img
                  src={'https://cdn-icons-png.flaticon.com/512/147/147144.png'}
                  alt={`${comment.user} profile`}
                  className="rounded-circle me-2"
                  style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                />

                {/* Comment details */}
                <div>
                  <strong>{comment.user} </strong>
                  <span style={{ fontSize: '0.85rem', color: '#aaa' }}>
                    {'9 months ago'}
                  </span>
                  <p className="mb-1">{comment.comment}</p>

                  {/* Like and Dislike Section */}
                  <div className="d-flex align-items-center">
                    <FaThumbsUp
                      style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        color: likedComments[comment.id] ? '#4387ff' : 'white', // Change color when liked
                      }}
                      onClick={() => handleLike(comment.id)} // Like action
                    />
                    <span className="me-3">{likedComments[comment.id] ? comment.likes + 1 : comment.likes}</span>

                    <FaThumbsDown
                      style={{
                        cursor: 'pointer',
                        marginRight: '5px',
                        color: dislikedComments[comment.id] ? 'red' : 'white', // Change color when disliked
                      }}
                      onClick={() => handleDislike(comment.id)} // Dislike action
                    />
                    <span className="me-3">{dislikedComments[comment.id] ? comment.dislikes + 1 : comment.dislikes}</span>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>

        <div className="col-lg-4">
          <h5 className=''>Recommended Videos</h5>
          {recommendedVideos.map((video) => (
            <div key={video.id} className="d-flex mb-3">
              <img src={video.thumbnail} alt={video.title} className="img-fluid me-2" style={{ width: '150px' }} />
              <div>
                <h6>{video.title}</h6>
                <p className=" mb-0">{video.channel}</p>
                <p className="">{video.views}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentDetails;
