import React from 'react';
import Card from './ExplorePage_Components/Card';
const Explore = () => {
  const portals = [
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'Out&About',
      members: '43.3k',
      posts: '78.6k',
      description: `Picture portal of places you've visited, 
Must be your own pictures and please include`,
    },
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'PhotoArt',
      members: '12.9k',
      posts: '53',
      description: 'Come share your photos! Take any everyday photo, a photo of your pet,',
    },
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'Out&About',
      members: '43.3k',
      posts: '78.6k',
      description: `Picture portal of places you've visited, 
Must be your own pictures and please include`,
    },
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'PhotoArt',
      members: '12.9k',
      posts: '53',
      description: 'Come share your photos! Take any everyday photo, a photo of your pet,',
    },
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'Out&About',
      members: '43.3k',
      posts: '78.6k',
      description: `Picture portal of places you've visited, 
Must be your own pictures and please include`,
    },
    {
      bgimage: '/images/fetch.png',
      image: '/images/fetch2.png',
      title: 'PhotoArt',
      members: '12.9k',
      posts: '53',
      description: 'Come share your photos! Take any everyday photo, a photo of your pet,',
    },
  ];
  return (
    <div className="explore-page">
      <div className="explore-header">
        <h1>Explore and discover</h1>
        <input type="text" placeholder="Engage with people & explore portals..." />
      </div>
      <div className='container'>
        <div className="featured-container">
          {portals.map((portal, index) => (
            <Card
              key={index}
              bgimage={portal.bgimage}
              image={portal.image}
              title={portal.title}
              members={portal.members}
              posts={portal.posts}
              description={portal.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
