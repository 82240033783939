import React, { useState, useEffect } from 'react';
//import bgImage from '../images/lovepik-rhythm-music-symbol-png-image_401428593_wh1200.png';

const AddMusicForm = () => {
  const [musicName, setMusicName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [musicFile, setMusicFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);

  useEffect(() => {
    const fetchCategories = () => {
      const dynamicCategories = [
        { id: 'pop', name: 'Pop' },
        { id: 'rock', name: 'Rock' },
        { id: 'jazz', name: 'Jazz' },
        { id: 'classical', name: 'Classical' },
        { id: 'hiphop', name: 'Hip Hop' },
      ];
      setCategories(dynamicCategories);
    };

    fetchCategories();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      musicName,
      category,
      musicFile,
      coverImage,
    });
  };

  return (
    // <div style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <div className="d-flex justify-content-center align-items-center mt-4">
      <div className="p-4 theme-background-color-one shadow uploadeMusicForm">
        <h3 className="text-center mb-5 text-white">Upload Music</h3>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicName" className="form-label">
                Release Title
              </label>
              <input
                type="text"
                className="form-control"
                id="musicName"
                placeholder="Enter release title"
                value={musicName}
                onChange={(e) => setMusicName(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicName" className="form-label">
                Artist Name
              </label>
              <input
                type="text"
                className="form-control"
                id="musicName"
                placeholder="Enter artist name"
                value={musicName}
                onChange={(e) => setMusicName(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicName" className="form-label">
                Release Title
              </label>
              <input
                type="text"
                className="form-control"
                id="musicName"
                placeholder="Enter release title"
                value={musicName}
                onChange={(e) => setMusicName(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicName" className="form-label">
                Artist Name
              </label>
              <input
                type="text"
                className="form-control"
                id="musicName"
                placeholder="Enter artist name"
                value={musicName}
                onChange={(e) => setMusicName(e.target.value)}
              />
            </div>

            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicName" className="form-label">
                Artist Name
              </label>
              <input
                type="text"
                className="form-control"
                id="musicName"
                placeholder="Enter artist name"
                value={musicName}
                onChange={(e) => setMusicName(e.target.value)}
              />
            </div>

            {/* Dynamic Category Dropdown */}
            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="category" className="form-label">
                Genre
              </label>
              <select
                className="form-select"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Genre</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="musicFile" className="form-label">
                Upload Music File
              </label>
              <input
                type="file"
                className="form-control"
                id="musicFile"
                accept="audio/*"
                onChange={(e) => setMusicFile(e.target.files[0])}
              />
            </div>

            {/* Cover Image Upload */}
            <div className="col-md-6 col-12 mb-4">
              <label htmlFor="coverImage" className="form-label">
                Upload Cover Image
              </label>
              <input
                type="file"
                className="form-control"
                id="coverImage"
                accept="image/*"
                onChange={(e) => setCoverImage(e.target.files[0])}
              />
            </div>
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn mb-5 theme-box-shadow theme-btn-one">
            Submit
          </button>
        </form>
      </div>
    </div>
    // </div>
  );
};

export default AddMusicForm;
