import React from 'react';
import { IoDocuments, IoPeople } from 'react-icons/io5';

const Card = ({ bgimage, image, title, members, posts, description }) => {
    return (
        <div className="explore-card ">
            <img className=' bg-img img-fluid' src={bgimage} alt={title} />
            <div className='explore-card-image'>
                <img className='img-fluid' src={image} alt={title} />
            </div>
            <div className="explore-card-content">
                <h3 className="explore-card-title m-0">{title}</h3>
                <p className="explore-card-meta d-flex m-0">
                    <span className=' d-flex align-items-center gap-1'><IoPeople />{members} members</span>
                    <span className=' d-flex align-items-center gap-1'><IoDocuments /> {posts} posts</span>
                </p>
                <p className="explore-card-description m-0">{description}</p>
            </div>
        </div>
    );
};

export default Card;
