import React from 'react'
import Header from './Header'
import Home from './Home'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Explore from './Explore'
import Bytes from './Bytes'
import AddMusicForm from './components/AddMusicForm';
import ContentDetails from './components/ContentDetails';
import LoginComponent from './components/LoginComponent';
import SellersClub from './components/SellersClub';
import KrizaarAffluence from './components/KrizaarAffluence';
import KrizaDrops from './components/KrizaDrops';
import AdJunction from './components/AdJunction';
import KrizaarCompliances from './components/KrizaarCompliances';
import Logistics from './components/Logistics';
import KrizaarPay from './components/KrizaarPay';
import Studios from './components/Studios'
import Streaming from './components/Streaming'
import Voting from './components/Voting'
import Moderation from './components/Moderation'
import Monetization from './components/Monetization'
// import Swap from './components/Swap';
// import Stake from './components/Stake';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/bytes" element={<Bytes />} />
          <Route path="/add-music" element={<AddMusicForm />} />
          <Route path="/video-details" element={<ContentDetails />} />
          {/* start others links */}
          <Route path="/sellersclub" element={<SellersClub />} />
          <Route path="/affluence" element={<KrizaarAffluence />} />
          <Route path="/shuffledrops" element={<KrizaDrops />} />
          <Route path="/adjunction" element={<AdJunction />} />
          <Route path="/compliance" element={<KrizaarCompliances />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/pay" element={<KrizaarPay />} />
          <Route path="/studios" element={<Studios />} />
          <Route path="/streaming" element={<Streaming />} />
          <Route path="/voting" element={<Voting />} />
          <Route path="/moderation" element={<Moderation />} />
          <Route path="/monetization" element={<Monetization />} />
          {/* <Route path="/swap" element={<Swap />} />
          <Route path="/stake" element={<Stake />} /> */}
          {/* end others links */}

          {/* Studios https://shuffle.studio/studios
          Streaming https://shuffle.studio/streaming
          Voting https://shuffle.studio/voting
          Moderation https://shuffle.studio/moderation
          Monetization https://shuffle.studio/monetization */}
        </Routes>
      </Router>
    </>
  )
}

export default App