import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Moderation = () => {
  const contentArray = [
    {
      id: 1,
      content: "Shuffle's decentralized moderation system allows the community to oversee content and enforce guidelines through algorithmic voting",
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      content: 'Moderation decisions are made collectively by the community, using transparent voting processes where users can upvote or downvote content, flag violations, and suggest rule changes.',
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      content: 'The enforcement of these decisions is driven by consensus, ensuring that moderation is fair, transparent, and community-driven.',
      image: '/images/pay/pic3.jpg'
    },
  ]

  const heroSectionItems = {
    subtitle: 'Community-Based Decentralized Moderation',
    image: '/images/pay/pic1.jpg'
  }

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Moderation?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Moderation
