import React from 'react'
import TrendingComponent from './HomePage_Components/TrendingComponent'
import Explore from './HomePage_Components/ExploreComponent'

const Home = () => {
  return (
    <>
      <TrendingComponent/>
      <Explore/>
    </>
  )
}

export default Home
