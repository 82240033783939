import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Studios = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Blockchain-Powered Distribution',
      content: 'Secure, limited-edition music releases with transparent ownership records.',
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'NFTs with Embedded Analytics',
      content: 'Track listener engagement, streaming data, and royalty distribution directly through the NFT.',
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      subtitle: 'Exclusive Access',
      content: 'Offer fans unique, collectible music releases with limited distribution rights, creating scarcity and value.',
      image: '/images/pay/pic3.jpg'
    },
    {
      id: 4,
      subtitle: '',
      content: 'Shuffle Studios is reshaping the future of music by empowering artists to monetize their work, protect their rights, and engage with fans in innovative ways',
      image: '/images/pay/pic5.jpg'
    }
  ]

  const heroSectionItems = {
    title: 'Shuffle Studios',
    subtitle: 'Revolutionizing Music Releases on the Blockchai',
    content2: 'Shuffle Studios is a cutting-edge music release platform powered by blockchain technology, offering artists a new way to distribute their music with limited distribution rights and transparent ownership. By integrating NFTs with embedded analytics, Shuffle Studios enables musicians to release exclusive tracks, maintain control over distribution, and gain insights into listener engagement.',
    image: '/images/pay/pic1.jpg'
  }

  return (
    <>
      <CommonOtherLinkComponent title="Features of Shuffle Studios" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Studios
