import React, { useState } from "react";
import { FaArrowDown, FaArrowUp, FaRegHeart, FaShare } from "react-icons/fa";
import { FiMessageCircle } from "react-icons/fi";
import { HiOutlineCollection } from "react-icons/hi";
import { PiShareFat } from "react-icons/pi";

const Bytes = () => {
    const [currentReel, setCurrentReel] = useState(0);
    const [animationDirection, setAnimationDirection] = useState(null);

    const reels = [
        {
            id: 1,
            image: "/images/bytes_cover.webp",
            title: "Music is The Answer 🎶🎵",
            username: "emrahis",
            followers: "3.90k followers",
        },
        {
            id: 2,
            image: "/images/bytes_cover.webp",
            title: "Feel the Beat 🎧",
            username: "dj_khaled",
            followers: "4.50k followers",
        },
        {
            id: 3,
            image: "/images/bytes_cover.webp",
            title: "Dance with the Rhythm 💃",
            username: "dj_sam",
            followers: "2.75k followers",
        },
    ];

    const nextReel = () => {
        if (animationDirection !== null) return;

        setAnimationDirection("up");
        setTimeout(() => {
            setCurrentReel((prev) => (prev + 1) % reels.length);
            setAnimationDirection(null);
        }, 500);
    };

    const prevReel = () => {
        if (animationDirection !== null) return;

        setAnimationDirection("down");
        setTimeout(() => {
            setCurrentReel((prev) => (prev - 1 + reels.length) % reels.length);
            setAnimationDirection(null);
        }, 500);
    };

    return (
        <div className="bytes-page-container">
            <div className="bytes-page-inside-container">
                <div className="bytes-page-reel">
                    {reels.map((reel, index) => (
                        <div
                            className={`bytes-page-card ${index === currentReel
                                ? animationDirection === "up"
                                    ? "active slide-up-in"
                                    : animationDirection === "down"
                                        ? "active slide-down-in"
                                        : "active"
                                : index === (currentReel - 1 + reels.length) % reels.length &&
                                    animationDirection === "up"
                                    ? "slide-up-out"
                                    : index === (currentReel + 1) % reels.length &&
                                        animationDirection === "down"
                                        ? "slide-down-out"
                                        : "hidden"
                                }`}
                            key={reel.id}
                        >
                            <img
                                src={reel.image}
                                alt="Reel"
                                className="bytes-page-card-image"
                            />
                            <div className="bytes-page-card-content">
                                <h3 className="bytes-page-card-title">{reel.title}</h3>
                                <div className="bytes-page-card-footer">
                                    <div className=" d-flex align-items-center">
                                        <img
                                            src="/images/creater-icon.webp"
                                            alt="Profile"
                                            className="bytes-page-card-avatar"
                                        />
                                        <div className=" d-flex flex-column justify-content-center">
                                            <span className="bytes-page-card-username">
                                                {reel.username}
                                            </span>
                                            <span className="bytes-page-card-followers">
                                                {reel.followers}
                                            </span>
                                        </div>
                                    </div>
                                    <button className="login-button">Follow</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bytes-page-card-actions">
                    <button className="bytes-page-action-button"><FaRegHeart /> <span>172</span></button>
                    <button className="bytes-page-action-button"><FiMessageCircle /> <span>20</span></button>
                    <button className="bytes-page-action-button"><PiShareFat /> <span>77</span></button>
                    <button className="bytes-page-action-button"><HiOutlineCollection /> <span>Collect</span></button>
                </div>
            </div>
            <div className="bytes-page-navigation-buttons">
                <button onClick={prevReel} className="bytes-page-nav-button up-button"><FaArrowUp/></button>
                <button onClick={nextReel} className="bytes-page-nav-button down-button"><FaArrowDown/></button>
            </div>
        </div>
    );
};

export default Bytes;
