import React, { useState } from 'react';
import Card from './Card';
import { useNavigate } from 'react-router-dom';

const Explore = () => {
    const [selectedTab, setSelectedTab] = useState('All');
    const navigate = useNavigate();

    const cards = [
        {
            image: '/images/card-img.webp',
            title: 'How I built a link in bio platform in 5 weeks',
            user: 'genwav',
            userIcon: '/images/creater-icon.webp',
            likes: 33,
            date: 'Jul 29',
            duration: '01:45',
            category: 'People & Blogs',
        },
        {
            image: '/images/card-img.webp',
            title: 'Is Fluid DEX The Next Major Innovation for DeFi?',
            user: 'defidad',
            userIcon: '/images/creater-icon.webp',
            likes: 166,
            date: 'Jul 27',
            duration: '01:00:47',
            category: 'Finance',
        },
        {
            image: '/images/card-img.webp',
            title: 'Exploring the New Music Trends of 2024',
            user: 'musicfan',
            userIcon: '/images/creater-icon.webp',
            likes: 89,
            date: 'Jul 25',
            duration: '02:30',
            category: 'Music',
        },
        {
            image: '/images/card-img.webp',
            title: 'The Ultimate Podcast Guide for Beginners',
            user: 'podcaster',
            userIcon: '/images/creater-icon.webp',
            likes: 47,
            date: 'Jul 23',
            duration: '00:55:22',
            category: 'Podcast',
        },
        {
            image: '/images/card-img.webp',
            title: 'Art in the Modern Era: A Deep Dive',
            user: 'artist123',
            userIcon: '/images/creater-icon.webp',
            likes: 102,
            date: 'Jul 21',
            duration: '01:15:30',
            category: 'Arts',
        },
        {
            image: '/images/card-img.webp',
            title: 'Top 10 Hobbies to Pick Up This Summer',
            user: 'hobbyist',
            userIcon: '/images/creater-icon.webp',
            likes: 56,
            date: 'Jul 20',
            duration: '01:10',
            category: 'Hobbies & Interests',
        },
        {
            image: '/images/card-img.webp',
            title: 'Comedy Skits That Will Make You Laugh Out Loud',
            user: 'comedian',
            userIcon: '/images/creater-icon.webp',
            likes: 134,
            date: 'Jul 18',
            duration: '00:45',
            category: 'Comedy',
        },
        {
            image: '/images/card-img.webp',
            title: 'Staying Fit: Tips and Tricks',
            user: 'fitnessguru',
            userIcon: '/images/creater-icon.webp',
            likes: 92,
            date: 'Jul 16',
            duration: '00:30:10',
            category: 'Health & Fitness',
        },
        {
            image: '/images/card-img.webp',
            title: 'Exploring the Lens Ecosystem: A Beginner\'s Guide',
            user: 'lensmaster',
            userIcon: '/images/creater-icon.webp',
            likes: 48,
            date: 'Jul 14',
            duration: '01:05:47',
            category: 'Lens Ecosystem',
        },
        {
            image: '/images/card-img.webp',
            title: 'Cooking Made Easy: Quick Recipes for Busy People',
            user: 'chefjohn',
            userIcon: '/images/creater-icon.webp',
            likes: 77,
            date: 'Jul 12',
            duration: '00:25:33',
            category: 'Food & Cooking',
        },
        {
            image: '/images/card-img.webp',
            title: 'Innovative Teaching Methods in Modern Education',
            user: 'educator',
            userIcon: '/images/creater-icon.webp',
            likes: 63,
            date: 'Jul 10',
            duration: '00:40:12',
            category: 'Education',
        },
    ];

    const categories = [
        'All', 
        'People & Blogs', 
        'Music', 
        'Podcast', 
        'Arts', 
        'Hobbies & Interests', 
        'Comedy', 
        'Health & Fitness', 
        'Lens Ecosystem', 
        'Food & Cooking', 
        'Education'
    ];

    const filteredCards = selectedTab === 'All' 
        ? cards 
        : cards.filter(card => card.category === selectedTab);

    return (
        <div className="home-explore-container">
            <div className="home-explore-header">
                <h1 className='text-white'>Explore</h1>
                <div className="home-explore-tabs">
                    {categories.map(category => (
                        <button 
                            key={category} 
                            className={selectedTab === category ? 'active' : ''} 
                            onClick={() => setSelectedTab(category)}
                        >
                            {category}
                        </button>
                    ))}
                </div>
            </div>
            <div className="home-explore-cards">
                {filteredCards.map((card, index) => (
                    <div onClick={() => navigate('/video-details')} >
                    <Card
                        key={index}
                        image={card.image}
                        title={card.title}
                        user={card.user}
                        userIcon={card.userIcon}
                        likes={card.likes}
                        date={card.date}
                        duration={card.duration}
                    />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Explore;
