import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const Monetization = () => {
  const contentArray = [
    {
      id: 1,
      content: "Shuffle is introducing a new monetization and analytics feature that empowers creators to earn directly from their content while gaining valuable insights into audience engagement.",
      image: '/images/pay/pic2.jpg'
    },
    {
      id: 2,
      content: 'The platform will offer various monetization options, such as tipping, paid subscriptions, and token-based rewards.',
      image: '/images/pay/pic4.jpg'
    },
    {
      id: 3,
      content: 'Integrated analytics will provide real-time data on content performance, helping creators understand their audience, track earnings, and optimize their strategies for better engagement and revenue growth.',
      image: '/images/pay/pic3.jpg'
    },
    {
      id: 3,
      content: 'This feature aims to give creators more control over their monetization while ensuring transparency and data-driven decision-making.',
      image: '/images/pay/pic4.jpg'
    },
  ]

  const heroSectionItems = {
    title: 'Upcoming Feature',
    subtitle: 'Monetization and Analytics for Shuffle',
    image: '/images/pay/pic1.jpg'
  }

  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Monetization?" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={undefined} />
    </>
  )
}

export default Monetization
