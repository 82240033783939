import React from 'react'
import CommonOtherLinkComponent from './common/CommonOtherLinkComponent'

const KrizaarCompliances = () => {
  const contentArray = [
    {
      id: 1,
      subtitle: 'Why KYC/KYB Matters',
      content: 'Ensuring a safe environment starts with verifying who you are. At Krizaar, every user undergoes KYC or KYB verification to maintain trust and transparency.',
      image: '/images/compliance/pic2.jpg'
    },
    {
      id: 2,
      subtitle: 'KYC Verification',
      content: "Individuals provide government-issued IDs and other relevant information, safeguarding the platform against fraudulent activity.",
      image: '/images/compliance/pic3.jpg'
    },
    {
      id: 3,
      subtitle: 'KYB Verification',
      content: 'Businesses submit registration details, ownership credentials, and more, ensuring only legitimate enterprises operate on Krizaar.',
      image: '/images/compliance/pic4.jpg'
    },
    {
      id: 4,
      subtitle: 'Why Blockchain?',
      content: 'Blockchain technology offers unmatched security, transparency, and privacy. Here’s how Krizaar leverages it:',
      image: '/images/compliance/pic5.jpg'
    },
    {
      id: 5,
      subtitle: 'Immutable Records',
      content: `Once your information is on the blockchain, it becomes a permanent, tamper-proof record—your identity and credentials can never be altered.`,
      image: '/images/compliance/pic6.jpg'
    },
    {
      id: 5,
      subtitle: 'Decentralized Storage',
      content: `Unlike traditional centralized databases, Krizaar uses blockchain to store your information across a secure, decentralized network— eliminating the risk of hacks or unauthorized access.`,
      image: '/images/compliance/pic7.jpg'
    }
      
  ]

  const heroSectionItems = {
    title: 'Welcome to Krizaar',
    subtitle: 'Empowering Secure and Private Transactions',
    content: 'Unlock a World of Opportunities—Connect with Millions Across Web2 and Web3',
    content2: "At Krizaar, security and trust are at the core of everything we do. We’ve implemented a cutting-edge KYC (Know Your Customer) and KYB (Know Your Business) verification process to ensure both buyers and sellers can interact with confidence. With the power of blockchain technology, we’ve taken privacy and security to a whole new level.",
    image: '/images/compliance/pic1.jpg'
  }

  const middleBannerContent = {
    title: "Soulbound Tokens",
    subtitle: "The Future of Verification",
    description: "To elevate security and privacy, Krizaar introduces soulbound tokens—a revolutionary blockchain technology concept. These non-transferable tokens represent your verified status and securely store your identity or business attributes on the blockchain.",
    steps: [
      {
        title: `What Are Soulbound Tokens?`,
        description: `Soulbound tokens are unique digital identifiers tied to your identity or business. They cannot be transferred, sold, or manipulated, ensuring your credentials are stored securely on the blockchain.`,
      },
      {
        title: `Privacy and Security on DL`,
        description: `By storing your KYC/KYB details on distributed ledger technology (DLT), your information remains encrypted, decentralized, and under your control. You won’t need to share personal details repeatedly—your verification stays within the Krizaar ecosystem, giving you full autonomy`,
      }
    ],
  };
  
  return (
    <>
      <CommonOtherLinkComponent title="" contentArray={contentArray} heroSectionItems={heroSectionItems} middleBannerContent={middleBannerContent} />
    </>
  )
}

export default KrizaarCompliances
