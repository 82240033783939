import React from 'react';
import { BsDot } from 'react-icons/bs';
import { FaRegHeart } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { PiDotsThreeOutlineVerticalThin } from 'react-icons/pi';

const Card = ({ image, title, user, userIcon, likes, date, duration }) => {
    return (
        <div className="card">
            <div className="card-image">
                <img src={image} alt={title} />
                <span className="card-duration">{duration}</span>
            </div>
            <div className="card-content d-flex gap-2">
                <div className='user-icon'>
                    <img src={userIcon} alt={user} />
                </div>
                <div className='other-details'>
                    <h3 className="card-title">{title}</h3>
                    <div className="card-details">
                        <span className="card-user">{user} <div className='verified-icon'> <MdVerified /> </div> </span>
                        <span className="card-dot"><BsDot /></span>
                        <span className="card-likes"><FaRegHeart /> {likes}</span>
                        <span className="card-dot"><BsDot /></span>
                        <span className="card-date">{date}</span>
                    </div>
                </div>
                <div className='three-dots'>
                    <PiDotsThreeOutlineVerticalThin />
                </div>
            </div>
        </div>
    );
};

export default Card;
